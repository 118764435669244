import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Network/WiFi/SuccessBox';
import DangerBox1 from 'components/Web_User_Interface/1080p_Series/Network/WiFi/DangerBox1';
import DangerBox2 from 'components/Web_User_Interface/1080p_Series/Network/WiFi/DangerBox2';
import PrimaryBox from 'components/Web_User_Interface/1080p_Series/Network/WiFi/PrimaryBox';
import WiFiTable from 'components/Web_User_Interface/1080p_Series/Network/WiFi/wifiTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Network Menu // WiFi",
  "path": "/Web_User_Interface/1080p_Series/Network/WiFi/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Your WiFi connection should already be set up by WPS or by the Installation Wizard. Use the WiFi scanner to connect your camera to a different WiFi network. Or use the scan results to check the signal strength at the camera´s installation spot.",
  "image": "./WebUI_1080p_SearchThumb_Network_WiFi.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Network_WiFi.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='Network Menu // WiFi' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Your WiFi connection should already be set up by WPS or by the Installation Wizard. Use the WiFi scanner to connect your camera to a different WiFi network. Or use the scan results to check the signal strength at the camera´s installation spot.' image='/images/Search/WebUI_1080p_SearchThumb_Network_WiFi.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Network_WiFi.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Netzwerk/WLAN/' locationFR='/fr/Web_User_Interface/1080p_Series/Network/WiFi/' crumbLabel="WiFi" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "network-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#network-menu",
        "aria-label": "network menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/abc4f813d6135bf5595619b96ebe618c/bb27a/1080p_Settings_Network_WLAN.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70.43478260869564%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC10lEQVQoz22SW0hUQRjHjxaFFhK6a7ZqYUuYq6kpYZQIpWFimiS20kObBnkJQfdyZubs5czZ8qFcFdEiaNUSeii1J+lB0CRaDC90UYMeorIianX3aHt1zyWOqxHVjz8D3wz/7z/MNwS5DgCQggYt7mzseXa1e6Ku6+nljnGNbUzTNq5pG9PYxmtsY3XdDm3rXYsRXaqpTUvPyMrJIQwk0JEAImTQ63ru9A7NuAccSwMO573nS2+/eOY/uRcW2YVFdu7D0kc3P/hguLHiXG1ZVXlefn5aBqEjQYuepChKp9P19dqX3atO96pzmf32wxUKBkR+bS3g9ay4/D9ZUeRejo7SF5osFRfR6YKzmTkEgEhPArRuttvt4iYCzwWDAZ7nfT4fy7Ksmw0J/MSjEaxWdzXoSk9osg/lERDBFj0J4N9mjuOCwSDHcX6/3+VysW6XNyS4pkcmtdlvumseWmBTYS6BEDKQ8HeyPxjyBtY8Hk8gIMXyPM+Fgmt+ry/gF0Vx4HZ7eWqirVI5UZ/8pFZFQIgMAFIUpdVq+/v7xRAr+l2CIPDCxhVWAsKyT/B+d4ocd/NGpzxOsSchOV0RC4/GESSAWj1ACJEkabPZZh53zAy3T01Nf30/Nzsz+/nd6xfTr24NOSYHBuf77qPm5vj4BLksLmZHVFFKtDQqAwkAAAzDlJ4pJQiCiNyeszsiO3ErQWwpSZXNqWX6XPnJavXhkiLlQaUiJSl6Z1TUtsjjiggpmQQQQkjTdHW1eles/FRWUn3xvhiZQqXcT6tUxgPKwvS0I8X5FQ3nK69UHSsryMrNSstU7VUqpQej0AZmsxljfM2KMY1pjK0Yt9LYQGNksdBmM4Nxa+t1zDCSsLQQm0YEoWS2WhmMw5KgGWxlGCsjIZU0Hd4PlwRFobAQQqb1ZPqP4/9CryOZtQagl34okAZmNEJESUM3Gk0mU7jXv06z2WwymSCEvwDAfcqj1Ls0UQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abc4f813d6135bf5595619b96ebe618c/e4706/1080p_Settings_Network_WLAN.avif 230w", "/en/static/abc4f813d6135bf5595619b96ebe618c/d1af7/1080p_Settings_Network_WLAN.avif 460w", "/en/static/abc4f813d6135bf5595619b96ebe618c/7f308/1080p_Settings_Network_WLAN.avif 920w", "/en/static/abc4f813d6135bf5595619b96ebe618c/b0250/1080p_Settings_Network_WLAN.avif 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/abc4f813d6135bf5595619b96ebe618c/a0b58/1080p_Settings_Network_WLAN.webp 230w", "/en/static/abc4f813d6135bf5595619b96ebe618c/bc10c/1080p_Settings_Network_WLAN.webp 460w", "/en/static/abc4f813d6135bf5595619b96ebe618c/966d8/1080p_Settings_Network_WLAN.webp 920w", "/en/static/abc4f813d6135bf5595619b96ebe618c/f16bf/1080p_Settings_Network_WLAN.webp 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/abc4f813d6135bf5595619b96ebe618c/81c8e/1080p_Settings_Network_WLAN.png 230w", "/en/static/abc4f813d6135bf5595619b96ebe618c/08a84/1080p_Settings_Network_WLAN.png 460w", "/en/static/abc4f813d6135bf5595619b96ebe618c/c0255/1080p_Settings_Network_WLAN.png 920w", "/en/static/abc4f813d6135bf5595619b96ebe618c/bb27a/1080p_Settings_Network_WLAN.png 1371w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/abc4f813d6135bf5595619b96ebe618c/c0255/1080p_Settings_Network_WLAN.png",
              "alt": "Web User Interface - 1080p Series - Network WiFi",
              "title": "Web User Interface - 1080p Series - Network WiFi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox1 mdxType="DangerBox1" />
    <EuiSpacer mdxType="EuiSpacer" />
    <WiFiTable mdxType="WiFiTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`If you cannot use the WPS service (Wireless Protected Setup) to integrate the camera into your local WiFi network - see our `}<a href="http://install.instar.de/" target="_blank" rel="noopener noreferrer">{`Installation Wizard`}</a>{` for further information for the WPS function - click search to start the WLAN scanner. Just choose your own network, add your WiFi password and click “Test”. Please disconnect the LAN cable and wait. The camera will automatically - within `}<strong parentName="p">{`max. 5 minutes`}</strong>{` - connect to your WiFi network. The cameras WiFi module will receive a new address from your router - in case you didn’t assign a `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/IP_Configuration/"
      }}>{`Static IP Address`}</a>{` to your camera yet. You might have to use the `}<a parentName="p" {...{
        "href": "/en/Quick_Installation/Alternative_IP_Scanner/"
      }}>{`INSTAR Camera Tool`}</a>{` again to rediscover your camera. Your camera is now connected via WiFi!`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <DangerBox2 mdxType="DangerBox2" />
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      